import { GProductLicenses } from '@daocloud-proto/ghippo/v1alpha1/gproductlicense.pb';
import { useGlobalStore } from '@/stores';
import { simpleErrorNoty } from '@/utils/noty';
import { i18n } from '@/plugins/vue-i18n';

export default async function getLicensesOverQuota() {
  try {
    const store = useGlobalStore();
    const {
      expiredLicenses = [], expireSoonLicenses = [],
    } = await GProductLicenses.GetGProductLicensesExpired({});

    store.setLicense({
      expiredLicenses,
      expireSoonLicenses,
    });
  } catch (e) {
    simpleErrorNoty(e, {
      name: i18n.t('components.LicenseExpire.expired'),
    });
  }
}
