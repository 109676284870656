import pLimit from 'p-limit';
import getUrlBase from './getUrlBase';

// 一般浏览器的最大并发是6 留下2条用来请求其它资源
const limit = pLimit(4);
const basePath = getUrlBase();

export const fetchHandler = ((...args) => limit(
  () => (window.originFetch || fetch)(...args),
).then(async (res) => {
  if (!res.url.endsWith('.css')) {
    return res;
  }

  const url = new URL(res.url);

  const content = await res.text();
  const assetPath = url.pathname.match(`${basePath}(.*)/css/.*`);

  // vue-cli
  let newContent = content.replaceAll('../fonts', `./${assetPath?.[1]}/fonts`);

  // rsbuild
  newContent = newContent.replaceAll('./fonts', `./${assetPath?.[1]}/fonts`);

  return new Response(newContent);
})) as typeof window.fetch;

export const getTemplateHandler = (template: string) => template.replaceAll(/<link\s* rel="[\w\s]*icon[\w\s]*"\s*( href=".*?")?\s*\/?>/g, '');
